<template>
  <div class="cart" id="vue-cart">
    <div class="page-title">
      <h2>Корзина</h2>
      <h2 v-if="items.length == 0">пуста</h2>
    </div>
    <div class="table-responsive">
      <fieldset>
        <table class="data-table cart-table" id="shopping-cart-table">
          <colgroup>
            <col width="1" />
            <col />
            <col width="1" />
            <col width="1" />
            <col width="1" />
            <col width="1" />
            <col width="1" />
          </colgroup>
          <thead v-if="items.length > 0">
            <tr class="first last">
              <th rowspan="1">&nbsp;</th>
              <th rowspan="1"><span class="nobr">Название</span></th>
              <th rowspan="1"></th>
              <th colspan="1" class="a-center">
                <span class="nobr">Цена за ед.</span>
              </th>
              <th class="a-center" rowspan="1">Кол-во</th>
              <th colspan="1" class="a-center">Всего</th>
              <th class="a-center" rowspan="1">&nbsp;</th>
            </tr>
          </thead>
          <tfoot>
            <tr class="first last">
              <td class="a-right last" colspan="50">
                <button
                  class="button btn-continue"
                  @click="redirect(meta.continue_shopping)"
                >
                  <span>Продолжить покупки</span>
                </button>
                <button
                  v-if="items.length > 0"
                  id="empty_cart_button"
                  class="button btn-empty"
                  title="Clear Cart"
                  value="empty_cart"
                  name="update_cart_action"
                  type="submit"
                >
                  <span>Очистить корзину</span>
                </button>
              </td>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="image">
                <a class="product-image" title="Sample Product" :href="item.url"
                  ><img width="75" alt="item.title" :src="item.image.x75"
                /></a>
              </td>
              <td>
                <h2 class="product-name">
                  <a :href="item.url">{{ item.title }}</a>
                </h2>
              </td>
              <td class="a-center">
                <a
                  title="Edit item parameters"
                  class="edit-bnt"
                  href="#configure/id/15945/"
                ></a>
              </td>
              <td class="a-right">
                <span class="cart-price">
                  <span class="price">{{ item.price }} руб.</span>
                </span>
              </td>
              <td class="a-center movewishlist">
                <input
                  maxlength="12"
                  class="input-text qty"
                  v-model.lazy="item.count"
                  @change="update_product_qty($event, item.id)"
                />
              </td>

              <td class="a-right movewishlist">
                <span class="cart-price">
                  <span class="price">{{ item.count * item.price }}</span>
                </span>
              </td>
              <td class="a-center last">
                <a
                  class="button remove-item"
                  title="Удалить из корзины"
                  @click="delete_from_cart(item.id)"
                  ><span><span>Удалить из корзины</span></span></a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </div>
    <!-- BEGIN CART COLLATERALS -->
    <div class="cart-collaterals row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4"></div>
      <div class="totals col-sm-4" v-if="items.length > 0">
        <div class="inner">
          <table
            class="table shopping-cart-table-total"
            id="shopping-cart-totals-table"
          >
            <colgroup>
              <col />
              <col width="1" />
            </colgroup>
            <tfoot>
              <tr>
                <td colspan="1" class="a-left" style="">
                  <strong>Итого</strong>
                </td>
                <td class="a-right" style="">
                  <strong
                    ><span class="price"
                      >{{ summary.total_price }} руб.</span
                    ></strong
                  >
                </td>
              </tr>
            </tfoot>
          </table>
          <ul class="checkout">
            <li>
              <button
                class="button btn-proceed-checkout"
                title="Перейти к оформлению"
                type="button"
                @click="redirect(meta.checkout_url)"
              >
                <span>Перейти к оформлению</span>
              </button>
            </li>
          </ul>
        </div>
        <!--inner-->
      </div>
    </div>

    <!--cart-collaterals-->
  </div>
</template>
<script>
export default {
  name: "DetailCart",
  data: () => {
    return {};
  },
  methods: {
    delete_from_cart(product_id) {
      this.$store.dispatch("delete_from_cart", { product_id: product_id });
    },
    update_product_qty(event, product_id) {
      this.$store.dispatch("update_product_qty", {
        product_id: product_id,
        quantity:
          parseInt(event.target.value) && parseInt(event.target.value) > 0
            ? parseInt(event.target.value)
            : 1
      });
    },
    redirect(url) {
      window.location.href = url;
    }
  },
  computed: {
    items() {
      return this.$store.state.items;
    },
    summary() {
      return this.$store.getters.summary;
    },
    meta() {
      return this.$store.state.meta;
    }
  }
};
</script>

<style></style>
