import Vue from "vue";
import VueAxios from "vue-plugin-axios";
import axios from "axios";
import TopCartApp from "./TopCart.vue";
import DatailCartApp from "./DetailCart.vue";
import store from "./store";
import $ from "jquery";
import Cookies from "js-cookie";
import Inputmask from "inputmask";

Vue.config.productionTip = false;

axios.defaults.headers.post["X-CSRFToken"] = Cookies.get("csrftoken");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

Vue.use(VueAxios, {
  axios
});

export const bus = new Vue();

if ($("#vue-cart").length) {
  new Vue({
    store,
    render: h => h(DatailCartApp),
    created() {
      $(".mega-menu-category").css("display", "none");
    }
  }).$mount("#vue-cart");
}

new Vue({
  store,
  render: h => h(TopCartApp),
  created() {
    bus.$on("deleteCartItem", () => {
      this.check_buttons_out_cart();
    });
    this.add_cart_btns_click_action();
    this.$store.dispatch("load_cart").then(() => {
      this.check_buttons_in_cart();
    });
  },
  methods: {
    check_buttons_in_cart() {
      let buttons = $(":button.button.btn-cart");
      for (let i = 0; i < buttons.length; i++) {
        if (
          $(buttons[i])[0].dataset.product_id &&
          this.$store.getters.product_ids.includes(
            parseInt($(buttons[i])[0].dataset.product_id)
          )
        ) {
          $(buttons[i])[0].disabled = true;
          $(buttons[i]).text("В корзине");
        }
      }
    },
    check_buttons_out_cart() {
      let buttons = $(":button:disabled.button.btn-cart");
      for (let i = 0; i < buttons.length; i++) {
        if (
          $(buttons[i])[0].dataset.product_id &&
          !this.$store.getters.product_ids.includes(
            parseInt($(buttons[i])[0].dataset.product_id)
          )
        ) {
          $(buttons[i])[0].disabled = false;
          $(buttons[i]).text("Добавить в корзину");
        }
      }
    },
    add_cart_btns_click_action() {
      let self = this,
        buttons = $(":button.button.btn-cart"),
        input_qty = $("#qty");
      for (let i = 0; i < buttons.length; i++) {
        if ($(buttons[i])[0].dataset.product_id) {
          $(buttons[i]).click(function() {
            self.$store
              .dispatch("add_to_cart", {
                action: "add",
                product_id: $(this)[0].dataset.product_id,
                quantity:
                  input_qty.length &&
                  parseInt(input_qty[0].dataset.product_id) ==
                    $(this)[0].dataset.product_id
                    ? parseInt(input_qty[0].value)
                    : 1
              })
              .then(function() {
                $(buttons[i])[0].disabled = true;
                $(buttons[i]).text("В корзине");
                self.$store.dispatch("load_cart");
              });
          });
        }
      }
    }
  }
}).$mount("#top-vue_cart");

Inputmask({ mask: "8(999) 999-99-99" }).mask(
  $(":input#phone.input-text.required-entry")
);
Inputmask({ regex:"[a-zA-Z0-9._%-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,4}"}).mask(
  $(":input#email.input-text.required-entry")
);
