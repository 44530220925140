import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { bus } from "../main";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    items: [],
    meta: {}
  },
  getters: {
    summary(state) {
      let result = { total_count: 0, total_price: 0 };
      for (let i = 0; i < state.items.length; i++) {
        result.total_count += parseInt(state.items[i].count);
        result.total_price +=
          parseFloat(state.items[i].price) * parseInt(state.items[i].count);
      }
      return result;
    },
    product_ids(state) {
      let ids = [];
      for (let i = 0; i < state.items.length; i++) {
        ids.push(state.items[i].id);
      }
      return ids;
    }
  },
  mutations: {
    setCart(state, payload) {
      state.items = payload.data.items;
      state.meta = payload.data.meta;
    },
    dummy(state, payload) {
      console.log(state, payload);
    },
    deleteProduct(state, payload) {
      state.items = state.items.filter(item => {
        return item.id != parseInt(payload.product_id);
      });
      bus.$emit("deleteCartItem");
    },
    updateProductQty(state, payload) {
      state.items.forEach(item => {
        if (item.id == payload.product_id) {
          item.count = payload.quantity;
        }
      });
    }
  },
  actions: {
    load_cart({ commit }) {
      return axios.get("/katalog/ajax/cart/status").then(response => {
        commit({
          type: "setCart",
          data: response.data
        });
        return response;
      });
    },
    add_to_cart({ commit }, payload) {
      commit({
        type: "dummy"
      });
      return axios.post("/katalog/ajax/cart/action", {
        action: "add",
        product_id: payload.product_id,
        quantity: payload.quantity ? payload.quantity : 1
      });
    },
    delete_from_cart({ commit }, payload) {
      axios
        .post("/katalog/ajax/cart/action", {
          action: "del",
          product_id: payload.product_id
        })
        .then(() => {
          commit({
            type: "deleteProduct",
            product_id: payload.product_id
          });
        });
    },
    update_product_qty({ commit }, payload) {
      axios
        .post("/katalog/ajax/cart/action", {
          action: "update_qty",
          product_id: payload.product_id,
          quantity: payload.quantity
        })
        .then(() => {
          commit({
            type: "updateProductQty",
            product_id: payload.product_id,
            quantity: payload.quantity
          });
        });
    }
  },
  modules: {}
});
