<template>
  <div class="top-cart-contain" id="top-vue_cart">
    <!-- Top Cart -->
    <div class="mini-cart">
      <div
        data-toggle="dropdown"
        data-hover="dropdown"
        class="basket dropdown-toggle"
      >
        <a href="shopping_cart.html">
          <span class="cart_count hidden-xs">
            {{ summary.total_count }} тов. / {{ summary.total_price }} руб.
          </span>
        </a>
      </div>
      <div>
        <div class="top-cart-content">
          <!--block-subtitle-->
          <ul class="mini-products-list" id="cart-sidebar">
            <li class="item" v-for="item in items" :key="item.id">
              <div class="item-inner">
                <a
                  class="product-image"
                  :title="'Перейти к ' + item.title"
                  :href="item.url"
                  ><img alt="Retis lapen casen" :src="item.image.x65" />
                </a>
                <div class="product-details">
                  <div class="access">
                    <a
                      class="btn-remove1"
                      @click="delete_from_cart(item.id)"
                      title="Удалить из карзины"
                    >
                      Remove
                    </a>
                  </div>
                  <!--access-->
                  <strong>{{ item.count }}</strong> x
                  <span class="price">{{ item.price }} руб.</span>
                  <p class="product-name">
                    <a :href="item.url">{{ item.title }}</a>
                  </p>
                </div>
              </div>
            </li>
            <li v-if="items.length == 0">
              <div class="item-inner">
                <strong>Корзина пуста.</strong>
              </div>
            </li>
          </ul>
          <!--actions-->
          <div class="actions">
            <button
              class="btn-checkout"
              v-if="summary.total_count > 0"
              @click="redirect(meta.checkout_url)"
            >
              <span>Оформить заказ</span>
            </button>
            <button @click="redirect(meta.cart_url)" class="view-cart">
              <span>Перейти в корзину</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Cart -->
    <div id="ajaxconfig_info" style="display: none">
      <a href="#/"></a> <input value="" type="hidden" />
      <input id="enable_module" value="1" type="hidden" />
      <input class="effect_to_cart" value="1" type="hidden" />
      <input
        class="title_shopping_cart"
        value="Go to shopping cart"
        type="hidden"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TopCart",
  data: () => {
    return {};
  },
  created() {
    //
  },
  methods: {
    delete_from_cart(product_id) {
      this.$store.dispatch("delete_from_cart", { product_id: product_id });
    },
    redirect(url) {
      window.location.href = url;
    }
  },
  computed: {
    items() {
      return this.$store.state.items;
    },
    summary() {
      return this.$store.getters.summary;
    },
    meta() {
      return this.$store.state.meta;
    }
  }
};
</script>

<style></style>
